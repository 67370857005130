import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import { Layout, Menu } from 'antd';
import { useLocation } from '@reach/router';

import { Category } from 'types';

const { Sider } = Layout;
const { SubMenu } = Menu;

type Data = {
  site: any;
  allCategory: {
    nodes: Category[];
  };
  allMdx: {
    edges: any;
  };
};

interface MdxPage {
  id: string;
  title: string;
  category: any;
  slug: string;
  order: number;
  push: (arg: object) => void;
}

export default function AppSidebar() {
  const [current, setCurrent] = useState('');
  const location = useLocation();

  const data: Data = useStaticQuery(graphql`
    query {
      allCategory(sort: { fields: order, order: ASC }) {
        nodes {
          id
          name
          description
          slug
          url
          order
        }
      }
      allMdx {
        edges {
          node {
            id
            slug
            frontmatter {
              title
              category
              order
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const allMdxPages: MdxPage[] = categoriesWithPages
      .map((e) => e.pages)
      .flat();
    const found = allMdxPages.find((p: MdxPage) =>
      location.pathname.includes(p.slug)
    );
    if (found) setCurrent(found.id);
    else setCurrent('');
  }, [location]);

  const categories = data.allCategory.nodes;

  const markdownPages = data.allMdx.edges.map((e: any) => ({
    ...e.node?.frontmatter,
    id: e.node?.id,
    slug: `/articles/${e.node.slug}`
  }));

  const groupByCategory = markdownPages.reduce(
    (group: MdxPage[], page: MdxPage) => {
      const { category } = page;
      group[category] = group[category] ?? [];
      group[category].push(page);
      return group;
    },
    {}
  );

  const categoriesWithPages = categories
    .sort((a, b) => a.order - b.order)
    .map((e) => {
      return {
        ...e,
        pages: groupByCategory[e.slug].sort(
          (a: MdxPage, b: MdxPage) => a.order - b.order
        )
      };
    });

  const onTitleClick = (val: any) => {
    const found = categoriesWithPages.find((e) => e.id === val.key);
    setCurrent(val.key);
    if (found) navigate(`/categories/${found.slug}`);
  };

  return (
    <Sider width="300" breakpoint="lg" collapsedWidth="0">
      <Menu
        theme="dark"
        selectedKeys={[current]}
        onSelect={(val) => setCurrent(val.key)}
      >
        {categoriesWithPages.map((e) => {
          if (e.pages && e.pages.length > 0) {
            return (
              <SubMenu key={e.id} title={e.name} onTitleClick={onTitleClick}>
                {e.pages.map((p: MdxPage) => {
                  return (
                    <Menu.Item key={p.id}>
                      <Link to={p.slug}>{p.title}</Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          }
          return (
            <Menu.Item key={e.id}>
              <Link to={`/categories/${e.slug}`}>{e.name}</Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
}
