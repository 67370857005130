import React from 'react';
import { navigate, graphql, useStaticQuery } from 'gatsby';
import { Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { MdxArticle, SiteMetadata } from '../types';

type Data = {
  allMdx: {
    edges: {
      node: MdxArticle;
    }[];
  };
  site: {
    siteMetadata: SiteMetadata;
  };
};

interface AutoCompleteOption {
  value: string;
  label: string;
}

const SearchArticle = () => {
  const data: Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          searchText
        }
      }
      allMdx {
        edges {
          node {
            id
            frontmatter {
              title
              category
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `);
  const { searchText } = data.site.siteMetadata;

  const {
    allMdx: { edges }
  } = data;

  const searchOptions: AutoCompleteOption[] = edges
    .map((e) => ({
      value: e.node.fields.slug,
      label: e.node.frontmatter.title,
      title: e.node.frontmatter.title
    }))
    .sort((a, b) => -b.label.localeCompare(a.label));

  return (
    <Select
      placeholder={searchText}
      showSearch
      allowClear
      options={searchOptions}
      filterOption={(input, option) =>
        option?.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={(value: string) => {
        value && navigate(value);
      }}
      size="large"
      onClear={() => navigate(-1)}
      style={{ width: '100%' }}
    ></Select>
  );
};
export default SearchArticle;
