import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Layout, Typography, Menu, Grid } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import { SiteMetadata } from 'types/SiteMetadata';

const { Header } = Layout;
const { useBreakpoint } = Grid;

type Data = {
  site: {
    siteMetadata: SiteMetadata;
  };
};

export default function AppHeader() {
  const screens = useBreakpoint();

  const data: Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          logoUrl
          ctaButtonUrl
          ctaButtonText
          linkUrl
          linkText
          logoLabel
        }
      }
    }
  `);
  const { title, logoUrl, logoLabel, linkUrl, linkText } =
    data.site.siteMetadata;

  return (
    <Header>
      <div>
        <Typography.Text
          strong
          style={{ fontSize: '18px' }}
          className="hide-on-mobile"
        >
          {screens.xs ? (
            <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <HomeOutlined />
              </div>
            </Link>
          ) : (
            <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
              <div>{logoLabel}</div>
            </Link>
          )}
        </Typography.Text>
      </div>

      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']}>
        <Menu.Item key="platform">
          <a href={linkUrl}>{linkText}</a>
        </Menu.Item>
      </Menu>
    </Header>
  );
}
