import React from 'react';
// @ts-ignore
import getPageContext from './getPageContext';
import '../src/styles/global.less';

function withRoot(Component: any) {
  class WithRoot extends React.Component {
    muiPageContext: any | undefined;

    constructor(props: any) {
      super(props);
      this.muiPageContext = getPageContext();
    }

    componentDidMount() {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles && jssStyles.parentNode) {
        jssStyles.parentNode.removeChild(jssStyles);
      }
    }

    render() {
      return (
        <>
          {(() => {
            return (
              // @ts-ignore
              <>
                <Component {...this.props} />
              </>
            );
          })()}
        </>
      );
    }
  }

  return WithRoot;
}

export default withRoot;
