import React from 'react';
import { Link, graphql } from 'gatsby';
import { Layout, Typography, Menu, Grid } from 'antd';
import Navigation from 'components/Navigation';
import withRoot from 'withRoot';
import AppHeader from 'components/layout/AppHeader';
import AppSiderbar from 'components/layout/AppSidebar';
import SearchArticle from 'components/SearchArticle';

const { Header, Content, Footer } = Layout;
const { useBreakpoint } = Grid;

const DefaultLayout = ({
  children,
  basePath
}: {
  children: React.ReactNode;
  basePath: string;
}) => {
  const screens = useBreakpoint();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <AppHeader />
      <Content
        style={{
          display: 'flex',
          justifyContent: screens.xs ? 'start' : 'center',
          padding: screens.xs ? 0 : '50px'
        }}
      >
        <Layout style={{ maxWidth: '1200px' }}>
          <AppSiderbar />
          <div className="contentWrapper">
            <div style={{ marginBottom: '24px' }}>
              <SearchArticle />
            </div>

            {children}
          </div>
        </Layout>
      </Content>
      <Footer style={{ textAlign: 'center' }}>OTC ©2021</Footer>
    </Layout>
  );
};

export default withRoot(DefaultLayout);
